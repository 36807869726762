var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pure from 'recompose/pure';
import Typography from '@material-ui/core/Typography';
import sanitizeRestProps from './sanitizeRestProps';
import { fieldPropTypes } from './types';
export var removeTags = function removeTags(input) {
  return input ? input.replace(/<[^>]+>/gm, '') : '';
};
var RichTextField = function RichTextField(_a) {
  var className = _a.className,
    source = _a.source,
    _b = _a.record,
    record = _b === void 0 ? {} : _b,
    stripTags = _a.stripTags,
    rest = __rest(_a, ["className", "source", "record", "stripTags"]);
  var value = get(record, source);
  if (stripTags) {
    return React.createElement(Typography, __assign({
      className: className,
      component: "span"
    }, sanitizeRestProps(rest)), removeTags(value));
  }
  return React.createElement(Typography, __assign({
    className: className,
    component: "span"
  }, sanitizeRestProps(rest)), React.createElement("span", {
    dangerouslySetInnerHTML: {
      __html: value
    }
  }));
};
var EnhancedRichTextField = pure(RichTextField);
EnhancedRichTextField.defaultProps = {
  addLabel: true,
  stripTags: false
};
EnhancedRichTextField.propTypes = __assign({}, Typography.propTypes, fieldPropTypes, {
  stripTags: PropTypes.bool
});
EnhancedRichTextField.displayName = 'EnhancedRichTextField';
export default EnhancedRichTextField;