var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, createStyles } from '@material-ui/core/styles';
import ContentSave from '@material-ui/icons/Save';
import classnames from 'classnames';
import { showNotification, translate } from 'ra-core';
var styles = function styles(_a) {
  var spacing = _a.spacing;
  return createStyles({
    button: {
      position: 'relative'
    },
    leftIcon: {
      marginRight: spacing.unit
    },
    icon: {
      fontSize: 18
    }
  });
};
var sanitizeRestProps = function sanitizeRestProps(_a) {
  var basePath = _a.basePath,
    className = _a.className,
    classes = _a.classes,
    saving = _a.saving,
    label = _a.label,
    invalid = _a.invalid,
    variant = _a.variant,
    translate = _a.translate,
    handleSubmit = _a.handleSubmit,
    handleSubmitWithRedirect = _a.handleSubmitWithRedirect,
    submitOnEnter = _a.submitOnEnter,
    record = _a.record,
    redirect = _a.redirect,
    resource = _a.resource,
    locale = _a.locale,
    showNotification = _a.showNotification,
    undoable = _a.undoable,
    rest = __rest(_a, ["basePath", "className", "classes", "saving", "label", "invalid", "variant", "translate", "handleSubmit", "handleSubmitWithRedirect", "submitOnEnter", "record", "redirect", "resource", "locale", "showNotification", "undoable"]);
  return rest;
};
var SaveButton = /** @class */function (_super) {
  __extends(SaveButton, _super);
  function SaveButton() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.handleClick = function (e) {
      var _a = _this.props,
        handleSubmitWithRedirect = _a.handleSubmitWithRedirect,
        invalid = _a.invalid,
        redirect = _a.redirect,
        saving = _a.saving,
        showNotification = _a.showNotification,
        onClick = _a.onClick;
      if (saving) {
        // prevent double submission
        e.preventDefault();
      } else {
        if (invalid) {
          showNotification('ra.message.invalid_form', 'warning');
        }
        // always submit form explicitly regardless of button type
        if (e) {
          e.preventDefault();
        }
        handleSubmitWithRedirect(redirect)();
      }
      if (typeof onClick === 'function') {
        onClick();
      }
    };
    return _this;
  }
  SaveButton.prototype.render = function () {
    var _a = this.props,
      className = _a.className,
      _b = _a.classes,
      classes = _b === void 0 ? {} : _b,
      invalid = _a.invalid,
      _c = _a.label,
      label = _c === void 0 ? 'ra.action.save' : _c,
      pristine = _a.pristine,
      redirect = _a.redirect,
      saving = _a.saving,
      submitOnEnter = _a.submitOnEnter,
      translate = _a.translate,
      _d = _a.variant,
      variant = _d === void 0 ? 'raised' : _d,
      icon = _a.icon,
      onClick = _a.onClick,
      rest = __rest(_a, ["className", "classes", "invalid", "label", "pristine", "redirect", "saving", "submitOnEnter", "translate", "variant", "icon", "onClick"]);
    var type = submitOnEnter ? 'submit' : 'button';
    return React.createElement(Button, __assign({
      className: classnames(classes.button, className),
      variant: variant,
      type: type,
      onClick: this.handleClick,
      color: saving ? 'default' : 'primary'
    }, sanitizeRestProps(rest)), saving && saving.redirect === redirect ? React.createElement(CircularProgress, {
      size: 18,
      thickness: 2,
      className: classes.leftIcon
    }) : React.cloneElement(icon, {
      className: classnames(classes.leftIcon, classes.icon)
    }), label && translate(label, {
      _: label
    }));
  };
  SaveButton.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    handleSubmitWithRedirect: PropTypes.func,
    invalid: PropTypes.bool,
    label: PropTypes.string,
    pristine: PropTypes.bool,
    redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
    saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    showNotification: PropTypes.func,
    submitOnEnter: PropTypes.bool,
    translate: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(['raised', 'flat', 'fab']),
    icon: PropTypes.element
  };
  SaveButton.defaultProps = {
    handleSubmitWithRedirect: function handleSubmitWithRedirect() {
      return function () {};
    },
    icon: React.createElement(ContentSave, null)
  };
  return SaveButton;
}(Component);
export { SaveButton };
var enhance = compose(translate, connect(undefined, {
  showNotification: showNotification
}), withStyles(styles));
export default enhance(SaveButton);