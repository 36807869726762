var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React from 'react';
import PropTypes from 'prop-types';
import ActionList from '@material-ui/icons/List';
import { Link } from 'react-router-dom';
import Button from './Button';
var ListButton = function ListButton(_a) {
  var _b = _a.basePath,
    basePath = _b === void 0 ? '' : _b,
    _c = _a.label,
    label = _c === void 0 ? 'ra.action.list' : _c,
    icon = _a.icon,
    rest = __rest(_a, ["basePath", "label", "icon"]);
  return React.createElement(Button, __assign({
    component: Link,
    to: basePath,
    label: label
  }, rest), icon);
};
ListButton.propTypes = {
  basePath: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element
};
ListButton.defaultProps = {
  icon: React.createElement(ActionList, null)
};
export default ListButton;