export var INITIALIZE_FORM = 'RA/INITIALIZE_FORM';
export var initializeForm = function initializeForm(initialValues) {
  return {
    type: INITIALIZE_FORM,
    payload: initialValues
  };
};
export var RESET_FORM = 'RA/RESET_FORM';
export var resetForm = function resetForm() {
  return {
    type: RESET_FORM
  };
};
export var BEFORE_LOCATION_CHANGE = 'RA/BEFORE_LOCATION_CHANGE';
export var beforeLocationChange = function beforeLocationChange(_a) {
  var payload = _a.payload,
    meta = _a.meta;
  return {
    type: BEFORE_LOCATION_CHANGE,
    payload: payload,
    meta: meta
  };
};