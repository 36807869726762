var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import classNames from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import MuiTextField from '@material-ui/core/TextField';
import { withStyles, createStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { translate } from 'ra-core';
var styles = createStyles({
  clearIcon: {
    height: 16,
    width: 0
  },
  visibleClearIcon: {
    width: 16
  },
  clearButton: {
    height: 24,
    width: 0
  },
  visibleClearButton: {
    width: 24
  }
});
/**
 * An override of the default Material-UI TextField which is resettable
 */
var ResettableTextField = /** @class */function (_super) {
  __extends(ResettableTextField, _super);
  function ResettableTextField() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.state = {
      showClear: false
    };
    _this.handleClickClearButton = function (event) {
      event.preventDefault();
      _this.props.onChange('');
    };
    _this.handleMouseDownClearButton = function (event) {
      event.preventDefault();
    };
    _this.handleFocus = function (event) {
      _this.setState({
        showClear: true
      });
      _this.props.onFocus && _this.props.onFocus(event);
    };
    _this.handleBlur = function (event) {
      _this.setState({
        showClear: false
      });
      _this.props.onBlur && _this.props.onBlur(event);
    };
    return _this;
  }
  ResettableTextField.prototype.render = function () {
    var _a, _b;
    var _c = this.props,
      translate = _c.translate,
      classes = _c.classes,
      clearAlwaysVisible = _c.clearAlwaysVisible,
      InputProps = _c.InputProps,
      value = _c.value,
      resettable = _c.resettable,
      disabled = _c.disabled,
      props = __rest(_c, ["translate", "classes", "clearAlwaysVisible", "InputProps", "value", "resettable", "disabled"]);
    var showClear = this.state.showClear;
    var clearButton = classes.clearButton,
      clearIcon = classes.clearIcon,
      visibleClearButton = classes.visibleClearButton,
      visibleClearIcon = classes.visibleClearIcon,
      restClasses = __rest(classes, ["clearButton", "clearIcon", "visibleClearButton", "visibleClearIcon"]);
    return React.createElement(MuiTextField, __assign({
      classes: restClasses,
      value: value,
      InputProps: __assign({
        endAdornment: resettable && value && React.createElement(InputAdornment, {
          position: "end"
        }, React.createElement(IconButton, {
          className: classNames(clearButton, (_a = {}, _a[visibleClearButton] = clearAlwaysVisible || showClear, _a)),
          "aria-label": translate('ra.action.clear_input_value'),
          title: translate('ra.action.clear_input_value'),
          disableRipple: true,
          onClick: this.handleClickClearButton,
          onMouseDown: this.handleMouseDownClearButton,
          disabled: disabled
        }, React.createElement(ClearIcon, {
          className: classNames(clearIcon, (_b = {}, _b[visibleClearIcon] = clearAlwaysVisible || showClear, _b))
        })))
      }, InputProps),
      disabled: disabled
    }, props, {
      onFocus: this.handleFocus,
      onBlur: this.handleBlur
    }));
  };
  ResettableTextField.propTypes = {
    classes: PropTypes.object.isRequired,
    clearAlwaysVisible: PropTypes.bool,
    disabled: PropTypes.bool,
    InputProps: PropTypes.object,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    resettable: PropTypes.bool,
    translate: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired
  };
  return ResettableTextField;
}(Component);
export default compose(translate, withStyles(styles))(ResettableTextField);