var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React, { Fragment, cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import { ReferenceManyFieldController } from 'ra-core';
export var ReferenceManyFieldView = function ReferenceManyFieldView(_a) {
  var children = _a.children,
    className = _a.className,
    currentSort = _a.currentSort,
    data = _a.data,
    ids = _a.ids,
    loadedOnce = _a.loadedOnce,
    page = _a.page,
    pagination = _a.pagination,
    perPage = _a.perPage,
    reference = _a.reference,
    referenceBasePath = _a.referenceBasePath,
    setPage = _a.setPage,
    setPerPage = _a.setPerPage,
    setSort = _a.setSort,
    total = _a.total;
  return React.createElement(Fragment, null, cloneElement(Children.only(children), {
    className: className,
    resource: reference,
    ids: ids,
    loadedOnce: loadedOnce,
    data: data,
    basePath: referenceBasePath,
    currentSort: currentSort,
    setSort: setSort,
    total: total
  }), pagination && total !== undefined && cloneElement(pagination, {
    page: page,
    perPage: perPage,
    setPage: setPage,
    setPerPage: setPerPage,
    total: total
  }));
};
ReferenceManyFieldView.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string
  }),
  data: PropTypes.object,
  ids: PropTypes.array,
  loadedOnce: PropTypes.bool,
  pagination: PropTypes.element,
  reference: PropTypes.string,
  referenceBasePath: PropTypes.string,
  setSort: PropTypes.func
};
/**
 * Render related records to the current one.
 *
 * You must define the fields to be passed to the iterator component as children.
 *
 * @example Display all the comments of the current post as a datagrid
 * <ReferenceManyField reference="comments" target="post_id">
 *     <Datagrid>
 *         <TextField source="id" />
 *         <TextField source="body" />
 *         <DateField source="created_at" />
 *         <EditButton />
 *     </Datagrid>
 * </ReferenceManyField>
 *
 * @example Display all the books by the current author, only the title
 * <ReferenceManyField reference="books" target="author_id">
 *     <SingleFieldList>
 *         <ChipField source="title" />
 *     </SingleFieldList>
 * </ReferenceManyField>
 *
 * By default, restricts the possible values to 25. You can extend this limit
 * by setting the `perPage` prop.
 *
 * @example
 * <ReferenceManyField perPage={10} reference="comments" target="post_id">
 *    ...
 * </ReferenceManyField>
 *
 * By default, orders the possible values by id desc. You can change this order
 * by setting the `sort` prop (an object with `field` and `order` properties).
 *
 * @example
 * <ReferenceManyField sort={{ field: 'created_at', order: 'DESC' }} reference="comments" target="post_id">
 *    ...
 * </ReferenceManyField>
 *
 * Also, you can filter the query used to populate the possible values. Use the
 * `filter` prop for that.
 *
 * @example
 * <ReferenceManyField filter={{ is_published: true }} reference="comments" target="post_id">
 *    ...
 * </ReferenceManyField>
 */
export var ReferenceManyField = function ReferenceManyField(_a) {
  var children = _a.children,
    props = __rest(_a, ["children"]);
  if (React.Children.count(children) !== 1) {
    throw new Error('<ReferenceManyField> only accepts a single child (like <Datagrid>)');
  }
  return React.createElement(ReferenceManyFieldController, __assign({}, props), function (controllerProps) {
    return React.createElement(ReferenceManyFieldView, __assign({}, props, __assign({
      children: children
    }, controllerProps)));
  });
};
ReferenceManyField.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  children: PropTypes.element.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  filter: PropTypes.object,
  label: PropTypes.string,
  perPage: PropTypes.number,
  record: PropTypes.object,
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string
  }),
  target: PropTypes.string.isRequired
};
ReferenceManyField.defaultProps = {
  filter: {},
  perPage: 25,
  sort: {
    field: 'id',
    order: 'DESC'
  },
  source: 'id',
  addLabel: true
};
export default ReferenceManyField;