var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React from 'react';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import Button from './Button';
// useful to prevent click bubbling in a datagrid with rowClick
var stopPropagation = function stopPropagation(e) {
  return e.stopPropagation();
};
var ShowButton = function ShowButton(_a) {
  var _b = _a.basePath,
    basePath = _b === void 0 ? '' : _b,
    _c = _a.label,
    label = _c === void 0 ? 'ra.action.show' : _c,
    _d = _a.record,
    record = _d === void 0 ? {} : _d,
    _e = _a.icon,
    icon = _e === void 0 ? React.createElement(ImageEye, null) : _e,
    rest = __rest(_a, ["basePath", "label", "record", "icon"]);
  return React.createElement(Button, __assign({
    component: Link,
    to: linkToRecord(basePath, record.id) + "/show",
    label: label,
    onClick: stopPropagation
  }, rest), icon);
};
ShowButton.propTypes = {
  basePath: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  icon: PropTypes.element
};
var enhance = shouldUpdate(function (props, nextProps) {
  return props.translate !== nextProps.translate || props.record && nextProps.record && props.record.id !== nextProps.record.id || props.basePath !== nextProps.basePath || props.record == null && nextProps.record != null;
});
export default enhance(ShowButton);