var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import withDefaultValue from './withDefaultValue';
export var isRequired = function isRequired(validate) {
  if (validate && validate.isRequired) {
    return true;
  }
  if (Array.isArray(validate)) {
    return !!validate.find(function (it) {
      return it.isRequired;
    });
  }
  return false;
};
export var FormFieldView = function FormFieldView(_a) {
  var input = _a.input,
    props = __rest(_a, ["input"]);
  return input ?
  // An ancestor is already decorated by Field
  React.createElement(props.component, __assign({
    input: input
  }, props)) : React.createElement(Field, __assign({}, props, {
    name: props.source,
    isRequired: isRequired(props.validate)
  }));
};
FormFieldView.propTypes = {
  component: PropTypes.any.isRequired,
  defaultValue: PropTypes.any,
  input: PropTypes.object,
  source: PropTypes.string,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array])
};
var FormField = withDefaultValue(FormFieldView);
export default FormField;