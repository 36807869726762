var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NavigationRefresh from '@material-ui/icons/Refresh';
import { refreshView as refreshViewAction } from 'ra-core';
import Button from './Button';
var RefreshButton = /** @class */function (_super) {
  __extends(RefreshButton, _super);
  function RefreshButton() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.handleClick = function (event) {
      var _a = _this.props,
        refreshView = _a.refreshView,
        onClick = _a.onClick;
      event.preventDefault();
      refreshView();
      if (typeof onClick === 'function') {
        onClick();
      }
    };
    return _this;
  }
  RefreshButton.prototype.render = function () {
    var _a = this.props,
      label = _a.label,
      refreshView = _a.refreshView,
      icon = _a.icon,
      rest = __rest(_a, ["label", "refreshView", "icon"]);
    return React.createElement(Button, __assign({
      label: label,
      onClick: this.handleClick
    }, rest), icon);
  };
  RefreshButton.propTypes = {
    label: PropTypes.string,
    refreshView: PropTypes.func.isRequired,
    icon: PropTypes.element
  };
  RefreshButton.defaultProps = {
    label: 'ra.action.refresh',
    icon: React.createElement(NavigationRefresh, null)
  };
  return RefreshButton;
}(Component);
var enhance = connect(null, {
  refreshView: refreshViewAction
});
export default enhance(RefreshButton);