var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import { withStyles, createStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import classnames from 'classnames';
import { addField, translate, FieldTitle } from 'ra-core';
var sanitizeRestProps = function sanitizeRestProps(_a) {
  var addLabel = _a.addLabel,
    allowEmpty = _a.allowEmpty,
    basePath = _a.basePath,
    choices = _a.choices,
    className = _a.className,
    component = _a.component,
    crudGetMatching = _a.crudGetMatching,
    crudGetOne = _a.crudGetOne,
    defaultValue = _a.defaultValue,
    filter = _a.filter,
    filterToQuery = _a.filterToQuery,
    formClassName = _a.formClassName,
    initializeForm = _a.initializeForm,
    input = _a.input,
    isRequired = _a.isRequired,
    label = _a.label,
    limitChoicesToValue = _a.limitChoicesToValue,
    locale = _a.locale,
    meta = _a.meta,
    onChange = _a.onChange,
    options = _a.options,
    optionValue = _a.optionValue,
    optionText = _a.optionText,
    perPage = _a.perPage,
    record = _a.record,
    reference = _a.reference,
    resource = _a.resource,
    setFilter = _a.setFilter,
    setPagination = _a.setPagination,
    setSort = _a.setSort,
    sort = _a.sort,
    source = _a.source,
    textAlign = _a.textAlign,
    translate = _a.translate,
    translateChoice = _a.translateChoice,
    validation = _a.validation,
    rest = __rest(_a, ["addLabel", "allowEmpty", "basePath", "choices", "className", "component", "crudGetMatching", "crudGetOne", "defaultValue", "filter", "filterToQuery", "formClassName", "initializeForm", "input", "isRequired", "label", "limitChoicesToValue", "locale", "meta", "onChange", "options", "optionValue", "optionText", "perPage", "record", "reference", "resource", "setFilter", "setPagination", "setSort", "sort", "source", "textAlign", "translate", "translateChoice", "validation"]);
  return rest;
};
var styles = function styles(theme) {
  return createStyles({
    root: {},
    chips: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    chip: {
      margin: theme.spacing.unit / 4
    },
    select: {
      height: 'auto',
      overflow: 'auto'
    }
  });
};
/**
 * An Input component for a select box allowing multiple selections, using an array of objects for the options
 *
 * Pass possible options as an array of objects in the 'choices' attribute.
 *
 * By default, the options are built from:
 *  - the 'id' property as the option value,
 *  - the 'name' property an the option text
 * @example
 * const choices = [
 *    { id: 'programming', name: 'Programming' },
 *    { id: 'lifestyle', name: 'Lifestyle' },
 *    { id: 'photography', name: 'Photography' },
 * ];
 * <SelectArrayInput source="tags" choices={choices} />
 *
 * You can also customize the properties to use for the option name and value,
 * thanks to the 'optionText' and 'optionValue' attributes.
 * @example
 * const choices = [
 *    { _id: 123, full_name: 'Leo Tolstoi', sex: 'M' },
 *    { _id: 456, full_name: 'Jane Austen', sex: 'F' },
 * ];
 * <SelectArrayInput source="authors" choices={choices} optionText="full_name" optionValue="_id" />
 *
 * `optionText` also accepts a function, so you can shape the option text at will:
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
 * <SelectArrayInput source="authors" choices={choices} optionText={optionRenderer} />
 *
 * `optionText` also accepts a React Element, that will be cloned and receive
 * the related choice as the `record` prop. You can use Field components there.
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
 * <SelectArrayInput source="authors" choices={choices} optionText={<FullNameField />}/>
 *
 * The choices are translated by default, so you can use translation identifiers as choices:
 * @example
 * const choices = [
 *    { id: 'programming', name: 'myroot.tags.programming' },
 *    { id: 'lifestyle', name: 'myroot.tags.lifestyle' },
 *    { id: 'photography', name: 'myroot.tags.photography' },
 * ];
 */
var SelectArrayInput = /** @class */function (_super) {
  __extends(SelectArrayInput, _super);
  function SelectArrayInput() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    /*
     * Using state to bypass a redux-form comparison but which prevents re-rendering
     * @see https://github.com/erikras/redux-form/issues/2456
     */
    _this.state = {
      value: _this.props.input.value || []
    };
    _this.handleChange = function (event) {
      _this.props.input.onChange(event.target.value);
      // HACK: For some reason, redux-form does not consider this input touched without calling onBlur manually
      _this.props.input.onBlur(event.target.value);
      _this.setState({
        value: event.target.value
      });
    };
    _this.renderMenuItemOption = function (choice) {
      var _a = _this.props,
        optionText = _a.optionText,
        translate = _a.translate,
        translateChoice = _a.translateChoice;
      if (React.isValidElement(optionText)) return React.cloneElement(optionText, {
        record: choice
      });
      var choiceName = typeof optionText === 'function' ? optionText(choice) : get(choice, optionText);
      return translateChoice ? translate(choiceName, {
        _: choiceName
      }) : choiceName;
    };
    _this.renderMenuItem = function (choice) {
      var optionValue = _this.props.optionValue;
      return React.createElement(MenuItem, {
        key: get(choice, optionValue),
        value: get(choice, optionValue)
      }, _this.renderMenuItemOption(choice));
    };
    return _this;
  }
  SelectArrayInput.prototype.componentWillReceiveProps = function (nextProps) {
    if (nextProps.input.value !== this.props.input.value) {
      this.setState({
        value: nextProps.input.value || []
      });
    }
  };
  SelectArrayInput.prototype.render = function () {
    var _this = this;
    var _a = this.props,
      choices = _a.choices,
      classes = _a.classes,
      className = _a.className,
      isRequired = _a.isRequired,
      label = _a.label,
      meta = _a.meta,
      options = _a.options,
      resource = _a.resource,
      source = _a.source,
      optionText = _a.optionText,
      optionValue = _a.optionValue,
      rest = __rest(_a, ["choices", "classes", "className", "isRequired", "label", "meta", "options", "resource", "source", "optionText", "optionValue"]);
    if (typeof meta === 'undefined') {
      throw new Error("The SelectInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details.");
    }
    var touched = meta.touched,
      error = meta.error,
      _b = meta.helperText,
      helperText = _b === void 0 ? false : _b;
    return React.createElement(FormControl, __assign({
      margin: "normal",
      className: classnames(classes.root, className),
      error: !!(touched && error)
    }, sanitizeRestProps(rest)), React.createElement(InputLabel, {
      htmlFor: source
    }, React.createElement(FieldTitle, {
      label: label,
      source: source,
      resource: resource,
      isRequired: isRequired
    })), React.createElement(Select, __assign({
      autoWidth: true,
      multiple: true,
      input: React.createElement(Input, {
        id: source
      }),
      value: this.state.value,
      error: !!(touched && error),
      renderValue: function renderValue(selected) {
        return React.createElement("div", {
          className: classes.chips
        }, selected.map(function (item) {
          return choices.find(function (choice) {
            return get(choice, optionValue) === item;
          });
        }).map(function (item) {
          return React.createElement(Chip, {
            key: get(item, optionValue),
            label: _this.renderMenuItemOption(item),
            className: classes.chip
          });
        }));
      },
      "data-testid": "selectArray"
    }, options, {
      onChange: this.handleChange
    }), choices.map(this.renderMenuItem)), touched && error && React.createElement(FormHelperText, {
      error: true
    }, error), helperText && React.createElement(FormHelperText, null, helperText));
  };
  return SelectArrayInput;
}(Component);
export { SelectArrayInput };
SelectArrayInput.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]).isRequired,
  optionValue: PropTypes.string.isRequired,
  resource: PropTypes.string,
  source: PropTypes.string,
  translate: PropTypes.func.isRequired,
  translateChoice: PropTypes.bool
};
SelectArrayInput.defaultProps = {
  classes: {},
  choices: [],
  options: {},
  optionText: 'name',
  optionValue: 'id',
  translateChoice: true
};
var EnhancedSelectArrayInput = compose(addField, translate, withStyles(styles))(SelectArrayInput);
export default EnhancedSelectArrayInput;