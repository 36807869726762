var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
export default (function (_a) {
  var alwaysOn = _a.alwaysOn,
    basePath = _a.basePath,
    component = _a.component,
    defaultValue = _a.defaultValue,
    formClassName = _a.formClassName,
    initializeForm = _a.initializeForm,
    input = _a.input,
    isRequired = _a.isRequired,
    label = _a.label,
    limitChoicesToValue = _a.limitChoicesToValue,
    locale = _a.locale,
    meta = _a.meta,
    options = _a.options,
    optionText = _a.optionText,
    optionValue = _a.optionValue,
    record = _a.record,
    resource = _a.resource,
    allowEmpty = _a.allowEmpty,
    source = _a.source,
    textAlign = _a.textAlign,
    translate = _a.translate,
    translateChoice = _a.translateChoice,
    rest = __rest(_a, ["alwaysOn", "basePath", "component", "defaultValue", "formClassName", "initializeForm", "input", "isRequired", "label", "limitChoicesToValue", "locale", "meta", "options", "optionText", "optionValue", "record", "resource", "allowEmpty", "source", "textAlign", "translate", "translateChoice"]);
  return rest;
});