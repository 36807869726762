var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import classnames from 'classnames';
import { getDefaultValues, translate, REDUX_FORM_NAME } from 'ra-core';
import FormInput from './FormInput';
import Toolbar from './Toolbar';
import CardContentInner from '../layout/CardContentInner';
var sanitizeRestProps = function sanitizeRestProps(_a) {
  var anyTouched = _a.anyTouched,
    array = _a.array,
    asyncBlurFields = _a.asyncBlurFields,
    asyncValidate = _a.asyncValidate,
    asyncValidating = _a.asyncValidating,
    autofill = _a.autofill,
    blur = _a.blur,
    change = _a.change,
    clearAsyncError = _a.clearAsyncError,
    clearFields = _a.clearFields,
    clearSubmit = _a.clearSubmit,
    clearSubmitErrors = _a.clearSubmitErrors,
    destroy = _a.destroy,
    dirty = _a.dirty,
    dispatch = _a.dispatch,
    form = _a.form,
    handleSubmit = _a.handleSubmit,
    initialize = _a.initialize,
    initialized = _a.initialized,
    initialValues = _a.initialValues,
    pristine = _a.pristine,
    pure = _a.pure,
    redirect = _a.redirect,
    reset = _a.reset,
    resetSection = _a.resetSection,
    save = _a.save,
    submit = _a.submit,
    submitFailed = _a.submitFailed,
    submitSucceeded = _a.submitSucceeded,
    submitting = _a.submitting,
    touch = _a.touch,
    translate = _a.translate,
    triggerSubmit = _a.triggerSubmit,
    undoable = _a.undoable,
    untouch = _a.untouch,
    valid = _a.valid,
    validate = _a.validate,
    props = __rest(_a, ["anyTouched", "array", "asyncBlurFields", "asyncValidate", "asyncValidating", "autofill", "blur", "change", "clearAsyncError", "clearFields", "clearSubmit", "clearSubmitErrors", "destroy", "dirty", "dispatch", "form", "handleSubmit", "initialize", "initialized", "initialValues", "pristine", "pure", "redirect", "reset", "resetSection", "save", "submit", "submitFailed", "submitSucceeded", "submitting", "touch", "translate", "triggerSubmit", "undoable", "untouch", "valid", "validate"]);
  return props;
};
var SimpleForm = /** @class */function (_super) {
  __extends(SimpleForm, _super);
  function SimpleForm() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.handleSubmitWithRedirect = function (redirect) {
      if (redirect === void 0) {
        redirect = _this.props.redirect;
      }
      return _this.props.handleSubmit(function (values) {
        return _this.props.save(values, redirect);
      });
    };
    return _this;
  }
  SimpleForm.prototype.render = function () {
    var _a = this.props,
      basePath = _a.basePath,
      children = _a.children,
      className = _a.className,
      invalid = _a.invalid,
      pristine = _a.pristine,
      record = _a.record,
      redirect = _a.redirect,
      resource = _a.resource,
      saving = _a.saving,
      submitOnEnter = _a.submitOnEnter,
      toolbar = _a.toolbar,
      undoable = _a.undoable,
      version = _a.version,
      rest = __rest(_a, ["basePath", "children", "className", "invalid", "pristine", "record", "redirect", "resource", "saving", "submitOnEnter", "toolbar", "undoable", "version"]);
    return React.createElement("form", __assign({
      className: classnames('simple-form', className)
    }, sanitizeRestProps(rest)), React.createElement(CardContentInner, {
      key: version
    }, Children.map(children, function (input) {
      return React.createElement(FormInput, {
        basePath: basePath,
        input: input,
        record: record,
        resource: resource
      });
    })), toolbar && React.cloneElement(toolbar, {
      basePath: basePath,
      handleSubmitWithRedirect: this.handleSubmitWithRedirect,
      handleSubmit: this.props.handleSubmit,
      invalid: invalid,
      pristine: pristine,
      record: record,
      redirect: redirect,
      resource: resource,
      saving: saving,
      submitOnEnter: submitOnEnter,
      undoable: undoable
    }));
  };
  return SimpleForm;
}(Component);
export { SimpleForm };
SimpleForm.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  record: PropTypes.object,
  resource: PropTypes.string,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  save: PropTypes.func,
  saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitOnEnter: PropTypes.bool,
  toolbar: PropTypes.element,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
  version: PropTypes.number
};
SimpleForm.defaultProps = {
  submitOnEnter: true,
  toolbar: React.createElement(Toolbar, null)
};
var enhance = compose(connect(function (state, props) {
  return {
    form: props.form || REDUX_FORM_NAME,
    initialValues: getDefaultValues(state, props),
    saving: props.saving || state.admin.saving
  };
}), translate,
// Must be before reduxForm so that it can be used in validation
reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
}));
export default enhance(SimpleForm);