var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React from 'react';
import PropTypes from 'prop-types';
import BulkDeleteWithConfirmButton from './BulkDeleteWithConfirmButton';
import BulkDeleteWithUndoButton from './BulkDeleteWithUndoButton';
var BulkDeleteButton = function BulkDeleteButton(_a) {
  var undoable = _a.undoable,
    props = __rest(_a, ["undoable"]);
  return undoable ? React.createElement(BulkDeleteWithUndoButton, __assign({}, props)) : React.createElement(BulkDeleteWithConfirmButton, __assign({}, props));
};
BulkDeleteButton.propTypes = {
  basePath: PropTypes.string,
  label: PropTypes.string,
  resource: PropTypes.string.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  undoable: PropTypes.bool,
  icon: PropTypes.element
};
BulkDeleteButton.defaultProps = {
  undoable: true
};
export default BulkDeleteButton;