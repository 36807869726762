var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import { addField, FieldTitle } from 'ra-core';
import sanitizeRestProps from './sanitizeRestProps';
var BooleanInput = /** @class */function (_super) {
  __extends(BooleanInput, _super);
  function BooleanInput() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.handleChange = function (event, value) {
      _this.props.input.onChange(value);
    };
    return _this;
  }
  BooleanInput.prototype.render = function () {
    var _a = this.props,
      className = _a.className,
      id = _a.id,
      input = _a.input,
      isRequired = _a.isRequired,
      label = _a.label,
      source = _a.source,
      resource = _a.resource,
      options = _a.options,
      fullWidth = _a.fullWidth,
      meta = _a.meta,
      rest = __rest(_a, ["className", "id", "input", "isRequired", "label", "source", "resource", "options", "fullWidth", "meta"]);
    var value = input.value,
      inputProps = __rest(input, ["value"]);
    return React.createElement(FormGroup, __assign({
      className: className
    }, sanitizeRestProps(rest)), React.createElement(FormControlLabel, {
      htmlFor: id,
      control: React.createElement(Switch, __assign({
        id: id,
        color: "primary",
        checked: !!value,
        onChange: this.handleChange
      }, inputProps, options)),
      label: React.createElement(FieldTitle, {
        label: label,
        source: source,
        resource: resource,
        isRequired: isRequired
      })
    }), meta.error && React.createElement(FormHelperText, {
      error: true
    }, meta.error));
  };
  return BooleanInput;
}(Component);
export { BooleanInput };
BooleanInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string,
  options: PropTypes.object
};
BooleanInput.defaultProps = {
  options: {}
};
export default addField(BooleanInput);