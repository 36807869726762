var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React, { Component, Children, cloneElement, isValidElement } from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { withRouter, Route } from 'react-router-dom';
import compose from 'recompose/compose';
import { translate } from 'ra-core';
import CardContentInner from '../layout/CardContentInner';
import TabbedShowLayoutTabs from './TabbedShowLayoutTabs';
var sanitizeRestProps = function sanitizeRestProps(_a) {
  var children = _a.children,
    className = _a.className,
    record = _a.record,
    resource = _a.resource,
    basePath = _a.basePath,
    version = _a.version,
    initialValues = _a.initialValues,
    staticContext = _a.staticContext,
    translate = _a.translate,
    tabs = _a.tabs,
    rest = __rest(_a, ["children", "className", "record", "resource", "basePath", "version", "initialValues", "staticContext", "translate", "tabs"]);
  return rest;
};
var getTabFullPath = function getTabFullPath(tab, index, baseUrl) {
  return "" + baseUrl + (tab.props.path ? "/" + tab.props.path : index > 0 ? "/" + index : '');
};
/**
 * Tabbed Layout for a Show view, showing fields grouped in tabs.
 *
 * Receives the current `record` from the parent `<Show>` component,
 * and passes it to its childen. Children should be Tab components.
 * The component passed as `tabs` props replaces the default material-ui's <Tabs> component.
 *
 * @example
 *     // in src/posts.js
 *     import React from 'react';
 *     import { Show, TabbedShowLayout, Tab, TextField } from 'react-admin';
 *
 *     export const PostShow = (props) => (
 *         <Show {...props}>
 *             <TabbedShowLayout>
 *                 <Tab label="Content">
 *                     <TextField source="title" />
 *                     <TextField source="subtitle" />
 *                </Tab>
 *                 <Tab label="Metadata">
 *                     <TextField source="category" />
 *                </Tab>
 *             </TabbedShowLayout>
 *         </Show>
 *     );
 *
 *     // in src/App.js
 *     import React from 'react';
 *     import { Admin, Resource } from 'react-admin';
 *
 *     import { PostShow } from './posts';
 *
 *     const App = () => (
 *         <Admin dataProvider={...}>
 *             <Resource name="posts" show={PostShow} />
 *         </Admin>
 *     );
 *     export default App;
 */
var TabbedShowLayout = /** @class */function (_super) {
  __extends(TabbedShowLayout, _super);
  function TabbedShowLayout() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  TabbedShowLayout.prototype.render = function () {
    var _a = this.props,
      basePath = _a.basePath,
      children = _a.children,
      className = _a.className,
      location = _a.location,
      match = _a.match,
      record = _a.record,
      resource = _a.resource,
      translate = _a.translate,
      version = _a.version,
      value = _a.value,
      tabs = _a.tabs,
      rest = __rest(_a, ["basePath", "children", "className", "location", "match", "record", "resource", "translate", "version", "value", "tabs"]);
    return React.createElement("div", __assign({
      className: className,
      key: version
    }, sanitizeRestProps(rest)), cloneElement(tabs, {
      // The location pathname will contain the page path including the current tab path
      // so we can use it as a way to determine the current tab
      value: location.pathname,
      match: match
    }, children), React.createElement(Divider, null), React.createElement(CardContentInner, null, Children.map(children, function (tab, index) {
      return tab && isValidElement(tab) ? React.createElement(Route, {
        exact: true,
        path: getTabFullPath(tab, index, match.url),
        render: function render() {
          return cloneElement(tab, {
            context: 'content',
            resource: resource,
            record: record,
            basePath: basePath
          });
        }
      }) : null;
    })));
  };
  return TabbedShowLayout;
}(Component);
export { TabbedShowLayout };
TabbedShowLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  location: PropTypes.object,
  match: PropTypes.object,
  record: PropTypes.object,
  resource: PropTypes.string,
  basePath: PropTypes.string,
  value: PropTypes.number,
  version: PropTypes.number,
  translate: PropTypes.func,
  tabs: PropTypes.element.isRequired
};
TabbedShowLayout.defaultProps = {
  tabs: React.createElement(TabbedShowLayoutTabs, null)
};
var enhance = compose(withRouter, translate);
export default enhance(TabbedShowLayout);