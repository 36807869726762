var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { addField, FieldTitle } from 'ra-core';
import sanitizeRestProps from './sanitizeRestProps';
var leftPad = function leftPad(nb) {
  if (nb === void 0) {
    nb = 2;
  }
  return function (value) {
    return ('0'.repeat(nb) + value).slice(-nb);
  };
};
var leftPad4 = leftPad(4);
var leftPad2 = leftPad(2);
/**
 * @param {Date} v value to convert
 * @returns {String} A standardized datetime (yyyy-MM-ddThh:mm), to be passed to an <input type="datetime-local" />
 */
var convertDateToString = function convertDateToString(v) {
  if (!(v instanceof Date) || isNaN(v)) return '';
  var yyyy = leftPad4(v.getFullYear());
  var MM = leftPad2(v.getMonth() + 1);
  var dd = leftPad2(v.getDate());
  var hh = leftPad2(v.getHours());
  var mm = leftPad2(v.getMinutes());
  return yyyy + "-" + MM + "-" + dd + "T" + hh + ":" + mm;
};
// yyyy-MM-ddThh:mm
var dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
/**
 * Converts a date from the Redux store, with timezone, to a date string
 * without timezone for use in an <input type="datetime-local" />.
 *
 * @param {Date | String} value date string or object
 */
var format = function format(value) {
  // null, undefined and empty string values should not go through convertDateToString
  // otherwise, it returns undefined and will make the input an uncontrolled one.
  if (value == null || value === '') {
    return '';
  }
  if (value instanceof Date) {
    return convertDateToString(value);
  }
  // valid dates should not be converted
  if (dateTimeRegex.test(value)) {
    return value;
  }
  return convertDateToString(new Date(value));
};
/**
 * Converts a datetime string without timezone to a date object
 * with timezone, using the browser timezone.
 *
 * @param {String} value Date string, formatted as yyyy-MM-ddThh:mm
 * @return {Date}
 */
var parse = function parse(value) {
  return new Date(value);
};
/**
 * Input component for entering a date and a time with timezone, using the browser locale
 */
export var DateTimeInput = function DateTimeInput(_a) {
  var className = _a.className,
    _b = _a.meta,
    touched = _b.touched,
    error = _b.error,
    input = _a.input,
    isRequired = _a.isRequired,
    label = _a.label,
    options = _a.options,
    source = _a.source,
    resource = _a.resource,
    rest = __rest(_a, ["className", "meta", "input", "isRequired", "label", "options", "source", "resource"]);
  return React.createElement(TextField, __assign({}, input, {
    className: className,
    type: "datetime-local",
    margin: "normal",
    error: !!(touched && error),
    helperText: touched && error,
    label: React.createElement(FieldTitle, {
      label: label,
      source: source,
      resource: resource,
      isRequired: isRequired
    }),
    InputLabelProps: {
      shrink: true
    }
  }, options, sanitizeRestProps(rest), {
    value: input.value
  }));
};
DateTimeInput.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string
};
DateTimeInput.defaultProps = {
  options: {}
};
export default addField(DateTimeInput, {
  format: format,
  parse: parse
});