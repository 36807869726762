var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : new P(function (resolve) {
        resolve(result.value);
      }).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function sent() {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import { Children, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import getContext from 'recompose/getContext';
import { userCheck as userCheckAction } from '../actions/authActions';
import { AUTH_GET_PERMISSIONS } from './types';
import { isLoggedIn as getIsLoggedIn } from '../reducer';
import warning from '../util/warning';
var isEmptyChildren = function isEmptyChildren(children) {
  return Children.count(children) === 0;
};
/**
 * After checking that the user is authenticated,
 * retrieves the user's permissions for a specific context.
 *
 * Useful for Route components ; used internally by Resource.
 * Use it to decorate your custom page components to require
 * a custom role. It will pass the permissions as a prop to your
 * component.
 *
 * Pass the `location` from the `routeParams` as `location` prop.
 * You can set additional `authParams` at will if your authProvider
 * requires it.
 *
 * @example
 *     import { WithPermissions } from 'react-admin';
 *
 *     const Foo = ({ permissions }) => (
 *         {permissions === 'admin' ? <p>Sensitive data</p> : null}
 *         <p>Not sensitive data</p>
 *     );
 *
 *     const customRoutes = [
 *         <Route path="/foo" render={routeParams =>
 *             <WithPermissions
 *                  location={routeParams.location}
 *                  authParams={{ foo: 'bar' }}
 *                  render={props => <Foo {...props} />}
 *              />
 *         } />
 *     ];
 *     const App = () => (
 *         <Admin customRoutes={customRoutes}>
 *             ...
 *         </Admin>
 *     );
 */
var WithPermissions = /** @class */function (_super) {
  __extends(WithPermissions, _super);
  function WithPermissions() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.cancelled = false;
    _this.state = {
      permissions: null
    };
    return _this;
  }
  WithPermissions.prototype.componentWillMount = function () {
    warning(this.props.render && this.props.children && !isEmptyChildren(this.props.children), 'You should not use both <WithPermissions render> and <WithPermissions children>; <WithPermissions children> will be ignored');
    this.checkAuthentication(this.props);
  };
  WithPermissions.prototype.componentDidMount = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.checkPermissions(this.props)];
          case 1:
            _a.sent();
            return [2 /*return*/];
        }
      });
    });
  };

  WithPermissions.prototype.componentWillUnmount = function () {
    this.cancelled = true;
  };
  WithPermissions.prototype.componentWillReceiveProps = function (nextProps) {
    if (nextProps.location !== this.props.location || nextProps.authParams !== this.props.authParams || nextProps.isLoggedIn !== this.props.isLoggedIn) {
      this.checkAuthentication(nextProps);
      this.checkPermissions(this.props);
    }
  };
  WithPermissions.prototype.checkAuthentication = function (params) {
    var userCheck = params.userCheck,
      authParams = params.authParams,
      location = params.location;
    userCheck(authParams, location && location.pathname);
  };
  WithPermissions.prototype.checkPermissions = function (params) {
    return __awaiter(this, void 0, void 0, function () {
      var authProvider, authParams, location, match, permissions, error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            authProvider = params.authProvider, authParams = params.authParams, location = params.location, match = params.match;
            _a.label = 1;
          case 1:
            _a.trys.push([1, 3,, 4]);
            return [4 /*yield*/, authProvider(AUTH_GET_PERMISSIONS, __assign({}, authParams, {
              routeParams: match ? match.params : undefined,
              location: location ? location.pathname : undefined
            }))];
          case 2:
            permissions = _a.sent();
            if (!this.cancelled) {
              this.setState({
                permissions: permissions
              });
            }
            return [3 /*break*/, 4];
          case 3:
            error_1 = _a.sent();
            if (!this.cancelled) {
              this.setState({
                permissions: null
              });
            }
            return [3 /*break*/, 4];
          case 4:
            return [2 /*return*/];
        }
      });
    });
  };
  // render even though the AUTH_GET_PERMISSIONS
  // isn't finished (optimistic rendering)
  WithPermissions.prototype.render = function () {
    var _a = this.props,
      authProvider = _a.authProvider,
      userCheck = _a.userCheck,
      isLoggedIn = _a.isLoggedIn,
      render = _a.render,
      children = _a.children,
      staticContext = _a.staticContext,
      props = __rest(_a, ["authProvider", "userCheck", "isLoggedIn", "render", "children", "staticContext"]);
    var permissions = this.state.permissions;
    if (render) {
      return render(__assign({
        permissions: permissions
      }, props));
    }
    if (children) {
      return children(__assign({
        permissions: permissions
      }, props));
    }
  };
  return WithPermissions;
}(Component);
export { WithPermissions };
var mapStateToProps = function mapStateToProps(state) {
  return {
    isLoggedIn: getIsLoggedIn(state)
  };
};
var EnhancedWithPermissions = compose(getContext({
  authProvider: PropTypes.func
}), connect(mapStateToProps, {
  userCheck: userCheckAction
}))(WithPermissions);
export default EnhancedWithPermissions;