var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React from 'react';
import get from 'lodash/get';
import pure from 'recompose/pure';
import sanitizeRestProps from './sanitizeRestProps';
import { fieldPropTypes } from './types';
var UrlField = function UrlField(_a) {
  var className = _a.className,
    source = _a.source,
    _b = _a.record,
    record = _b === void 0 ? {} : _b,
    rest = __rest(_a, ["className", "source", "record"]);
  return React.createElement("a", __assign({
    className: className,
    href: get(record, source)
  }, sanitizeRestProps(rest)), get(record, source));
};
var EnhancedUrlField = pure(UrlField);
EnhancedUrlField.defaultProps = {
  addLabel: true
};
EnhancedUrlField.propTypes = fieldPropTypes;
EnhancedUrlField.displayName = 'EnhancedUrlField';
export default EnhancedUrlField;