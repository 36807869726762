var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
var styles = createStyles({
  cardActions: {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0
  }
});
var CardActions = function CardActions(_a) {
  var classes = _a.classes,
    className = _a.className,
    children = _a.children,
    rest = __rest(_a, ["classes", "className", "children"]);
  return React.createElement("div", __assign({
    className: classnames(classes.cardActions, className)
  }, rest), children);
};
CardActions.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string
};
export default withStyles(styles)(CardActions);